﻿.custom_calendar_1 {
    background: #3A8AD9 !important;
    border-color: #3A8AD9 !important;
}

.custom_calendar_2 {
    background: #4FA9F2 !important;
    border-color: #4FA9F2 !important;
}

.custom_calendar_3 {
    background: #87CDF6 !important;
    border-color: #87CDF6 !important;
}

.custom_calendar_4 {
    background: #AEE7F8 !important;
    border-color: #AEE7F8 !important;
}

.custom_calendar_5 {
    background: #CFFFF6 !important;
    border-color: #CFFFF6 !important;
}

.custom_calendar_6 {
    background: #ABF1BC !important;
    border-color: #ABF1BC !important;
}

.custom_calendar_7 {
    background: #22E4AC !important;
    border-color: #22E4AC !important;
}

.custom_calendar_8 {
    background: #00CC98 !important;
    border-color: #00CC98 !important;
}

.custom_calendar_9 {
    background: #009D74 !important;
    border-color: #009D74 !important;
}
